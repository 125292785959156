:root {
  --darkest-teal: rgba(11, 15, 21, 0.9);
  --darker-teal: rgba(17, 30, 42, 0.9);
  --light-teal: rgb(71, 109, 122);
  --blue: rgb(14, 33, 56);
  --light-blue: rgba(34, 99, 157);
  --lighter-blue: rgb(113, 189, 254);
  --lightest-blue: rgb(118, 189, 254);

  --darkest-gold: rgb(34, 17, 3);
  --darker-gold: rgb(93, 29, 5);
  --light-gold: rgb(159, 96, 6);
  --lighter-gold: rgb(68, 44, 21);
  --lightest-gold: rgb(180, 159, 100);
}

.color-darkest-teal{color: var(--darkest-teal) !important;}
.color-darker-teal{color: var(--darker-teal) !important;}
.color-light-teal{color: var(--light-teal) !important;}
.color-blue{color: var(--blue) !important;}
.color-light-blue{color: var(--light-blue) !important;}
.color-lighter-blue{color: var(--lighter-blue) !important;}
.color-lightest-blue{color: var(--lightest-blue) !important;}
.color-darkest-gold{color: var(--darkest-gold) !important;}
.color-darker-gold{color: var(--darker-gold) !important;}
.color-light-gold{color: var(--light-gold) !important;}
.color-lighter-gold{color: var(--lighter-gold) !important;}
.color-lightest-gold{color: var(--lightest-gold) !important;}


body {
  margin: 0;
  font-family: 'Saira', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: url('./sc_background.jpg') fixed center;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  color: var(--light-gold);
}

.accordion, .card{
  color: white;

  box-shadow: 0 0 5px 1px rgba(0,0,0, 0.9);
  background: rgba(11, 15, 21, 0.5);
  border: 2px solid var(--darker-teal);

  border-radius: 2px;

  backdrop-filter: blur(3px);
}
.accordion-header, .card-header{
  /* background-color: rgba(0,100,255,0.1); */
  background-color: transparent;
  text-transform: uppercase;
  border-bottom-color: var(--darker-teal);

  background: rgba(11, 15, 21, 0.5);
  backdrop-filter: blur(3px);
}
.accordion-button,
.accordion-item{
  background: transparent;
}

.accordion-button{
  color: white;
}
.accordion-button:not(.collapsed){
  color: white;
  background-color: transparent;
}
.accordion-button:focus{
  outline:0;
  box-shadow: none;
}


.btn{
  border-radius: 2px;
  font-weight: bold;
}
.btn:focus{
}

.btn-primary, .btn-secondary{
  margin: 7px; /* allow for box-shadow borders*/
}

.btn-primary{
  text-transform: uppercase;
  color: var(--lightest-gold);
  text-shadow: 1px 1px 0 black;
  background: var(--darkest-gold);
  background-image: radial-gradient(at bottom, var(--darker-gold), transparent, transparent);
  border: 1px solid black !important;
  box-shadow: 0 0 0 3px var(--light-gold),
              0 0 0 4px black,
              0 0 0 7px var(--lighter-gold),
              0 0 3px 8px black;
}
.btn-primary:hover{
  color: white;
  outline: none;

  background: var(--darker-gold);
}
.btn-primary:focus{
  background: var(--darkest-gold);
  box-shadow: 0 0 0 3px var(--light-gold),
              0 0 0 4px black,
              0 0 0 7px var(--lighter-gold),
              0 0 3px 8px black !important;
}

.btn-secondary{
  text-transform: uppercase;
  color: var(--lightest-blue);
  text-shadow: 1px 1px 0 black;
  background: var(--darkest-teal);
  background-image: radial-gradient(at bottom, var(--darker-teal), transparent, transparent);
  border: 1px solid black !important;
  box-shadow: 0 0 0 3px var(--light-blue),
              0 0 0 4px black,
              0 0 0 7px var(--lighter-blue),
              0 0 3px 8px black;
}
.btn-secondary:hover{
  color: white;
  outline: none;

  background: var(--darker-teal);
}
.btn-secondary:focus{

  background: var(--darkest-teal);
  box-shadow: 0 0 0 3px var(--light-blue),
              0 0 0 4px black,
              0 0 0 7px var(--lighter-blue),
              0 0 3px 8px black !important;
}
.btn-secondary{}


.form-control{
  background: var(--darkest-teal);
  border: 1px solid var(--light-teal);
  border-radius: 3px;
  color: white;
  
  box-shadow: 0 0 2px 0 black;
}
.form-control:focus{
  color: white;
  background-color: var(--darkest-teal);
  border-color: var(--lightest-blue);
  outline: 0;
  
  box-shadow: 0 0 0 3px var(--light-blue),
              0 0 2px 0 black;
}
.form-control::placeholder {
  color: var(--light-teal);
}
.form-select{
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: white;
  background-color: var(--darkest-teal);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='gray' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border: 1px solid var(--light-teal);
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,
              box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
.form-select:focus{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='teal' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");

  border-color: var(--lightest-blue);
  outline: 0;
  
  box-shadow: 0 0 0 3px var(--light-blue),
              0 0 2px 0 black;
}


.alert{}
.alert-primary{ 
  border: 0;
  color: var(--darkest-teal);
  background: rgba(118, 189, 254, 0.8);
}