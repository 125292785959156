

.player-card{}

.player-card > *{
    position: relative;
    z-index:1;
}

/* .player-card::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
} */

.player-a{
    background-color: darkred;
    border: 1px solid darkred;
    box-shadow: 0 0 5px 0 darkred,
        inset 0 -8px 30px 0 rgba(255, 0, 0, 0.5);  
}
/* .player-a::before{
    background-image: linear-gradient(transparent, darkred);
} */
.player-a .player-name{
    right: 0;
    background: darkred;
}

.player-a .player-name::before{
    content: '';
    position: absolute;
    top: 0;
    right: 100%;
    height: 0;
    border: 1px solid transparent;
    border-width: 40px 20px 0 0;
    border-color: transparent darkred;
}

.player-b{
    background-color: blue;
    border: 1px solid blue;
    box-shadow: 0 0 5px 0 blue,
        inset 0 -8px 30px 0 rgba(0, 0, 255, 0.5);
}
/* .player-b::before{
    background-image: linear-gradient(transparent, blue);
} */
.player-b .player-name{
    left: 0;
    background: blue;
}
.player-b .player-name::before{
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    height: 0;
    border: 1px solid transparent;
    border-width: 40px 0 0 20px;
    border-color: transparent blue;
}

.player-card{
    border-radius: 3px;
    position: relative;

    padding: 1rem;
    padding-top: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    background-size: cover;
    background-position: center;
}

.player-name{
    position: absolute;
    bottom: 0;
    /* width: 100%; */
    margin: 0;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    height: 40px;
    text-shadow: 1px 1px 2px black;
}

.player-card-terran{
    background-image: url('../../images/race/terran.png')
}

.player-card-zerg{
    background-image: url('../../images/race/zerg.png')
}

.player-card-protoss{
    background-image: url('../../images/race/protoss.png')
}

.player-card-random{
    background-image: url('../../images/race/random.png');
    background-position: bottom;
}