.summary-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 100px;
    grid-gap: 1rem;

    margin: 3rem 0;
    text-shadow: 3px 2px 4px black;

}

.picks{
    grid-column: 2;
    position: relative;
    align-self: center;
    text-align: center;
    /* background: var(--darkest-teal) */
    padding: 0.25rem 1rem;
}

.picks::before{
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 60px;
}
.pick-a::before{
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    border: 1px solid blue;
    border-width: 32px 20px;
    border-color: transparent rgba(255,255,255, 0.1) transparent transparent;
    transform: translateX(-40px);

}
.pick-b::before{
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    border: 1px solid blue;
    border-width: 32px 20px;
    border-color: transparent transparent transparent rgba(255,255,255, 0.1);
    transform: translateX(40px);
}

.pick-a{
    background: linear-gradient(to left, transparent, rgba(255,255,255, 0.1));
}
.pick-b{
    background: linear-gradient(to right, transparent, rgba(255,255,255, 0.1));
}

