.nav{
  background: var(--darkest-teal);
  background-image: linear-gradient(var(--darkest-teal), var(--darker-teal));
  border: 1px solid var(--light-teal);
  border-width: 1px 0;


}

.nav-link, .nav-link:hover{
  color: var(--light-teal);
  text-transform: uppercase;

  border: 1px solid var(--light-teal);
  margin-top:-1px;
  margin-bottom:-1px;
}
.nav-link.active{
  color: white;
  border-color: var(--lightest-blue);
  box-shadow: 0 0 5px 0 var(--lightest-blue);
  background: radial-gradient(at bottom, rgba(118, 189, 254, 0.1), transparent, transparent);
}

.feedback{
  position: fixed;
  right:0;
  top: 10%;
  transform: rotate(-90deg);
  transform-origin: bottom right;
  color: white;
  background: #111;
  text-decoration: none;
  border: 1px solid #333;
}
.feedback:hover{
  color: var(--lightest-blue);
  background: #222;
}