.modal-content{
    color: white;
    background: #010917;
    /* background: var(--darkest-teal); */
    background-image: radial-gradient(at bottom, var(--darker-teal), transparent, transparent);
    border: 1px solid black !important;
    box-shadow: 0 0 0 3px var(--light-blue),
                0 0 0 4px black,
                0 0 20px 0 var(--lighter-blue)
}
.modal-header{
    border: 0;
    /* padding: 0.5rem 1rem; */
    justify-content: center;
    position: relative;
}
.modal-header::before{
    content: '';
    height: 1px;
    width: 90%;
    position: absolute;
    bottom:0;
    left: 50%;
    transform: translateX(-50%);
    background: var(--light-blue);
    box-shadow: 0 0 5px 0 var(--lighter-blue);
}
.modal-body{}
.modal-footer{
    border: 0;
    justify-content: center;
}
