.picked-grid{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
}

@media (min-width: 576px){
    .picked-grid{
        grid-template-columns: repeat(2, 1fr);
    }
}



.pick-checkbox{
    display:none;
}
.pick-checkbox:checked + label {
    background-color: var(--darkest-teal);

    border-color: var(--lightest-blue);
    box-shadow: 0 0 5px 0 var(--lightest-blue),
        inset 0 -8px 30px 0 rgba(118, 189, 254, 0.5);
    filter: opacity(100%);
}

.pick-checkbox:checked + label::before{
    font-weight: bold;
    text-shadow: 0 0 10px black;
    /* opacity: 0.8; */
    font-size: 70px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.pick-checkbox:checked + label.pick{
    border-color: green;
    box-shadow: 0 0 5px 0 green,
        inset 0 -8px 30px 0 rgba(0, 255, 0, 0.5);
}
.pick-checkbox:checked + label.pick::before{
    content: '\2713';
    color: green;
}

.pick-checkbox:checked + label.veto {
    border-color: red;
    box-shadow: 0 0 5px 0 red,
        inset 0 -8px 30px 0 rgba(255, 0, 0, 0.5);
}
.pick-checkbox:checked + label.veto::before{
    content: '\2716';
    color: red;
}

.pick-checkbox-label{
    border-radius: 4px;

    border: 2px solid var(--blue);

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: inset 0 0px 20px 0 black;

    /* padding-top: 100%; */
    height:75px;
    /* filter: opacity(30%); */
    display: flex;
    justify-content: center;
    padding: 0.5rem 2rem;
}

@media (min-width: 576px){
    .pick-checkbox-label{
        height: 125px;;
    }
}

.pick-checkbox-label:hover {
    filter: opacity(100%);
    /* transition: filter 0.1s ease-in; */
}

.pick-checkbox-label-name{
    color: var(--lightest-blue);
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 2px 2px 0 var(--darkest-teal);
}

.pick-checkbox:checked + label .pick-checkbox-label-name{
    color: white;
}
