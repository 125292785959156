.avatar{
    background-size: contain;
    background-repeat: no-repeat;
    background-color: black;
    border-radius: 4px;
    background-position: center;
    border: 1px solid white;

    /* box-shadow: 0 0 0 1px var(--light-teal); */
}

.map-avatar{
    width: 30px;
    height: 30px;
    flex-shrink: 0;
}

.label-avatar{
    width: 50px;
    height: 50px;
}

.map_list_area{
    max-height: 500px;
    overflow: auto;
    padding: 1.25rem 0;
}

/* Map List Checkbox Styles*/
.map-checkbox{
    display:none;
}

.map-checkbox-label{
    cursor: pointer;
    position: relative;
    border-radius: 4px;
    border: 1px solid var(--blue);

    display: flex;
    padding: 0.75rem;
    color: black;
    text-shadow: 1px 1px 0 black,
                1px 1px 3px rgba(0,0,0,0.5);

}

.map-checkbox:checked + label {
    color: var(--lightest-blue);
    border-color: var(--lighter-blue);
    box-shadow: 0 0 0 2px var(--lightest-blue);
}


    .map-checkbox-label-bg{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        /* background: none !important; */
        box-shadow: inset 0 0px 10px 0 rgba(0,0,0,0.5);
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height:100%;
        z-index:-1;
        opacity: 1;
        
        filter: grayscale(1);
    }
    .map-checkbox-label-check{
        width: 30px;
        height:30px;
        border-radius: 2px;
        border: 1px solid;
        box-shadow: inset 0 0px 20px 0 black;
        margin-right: 1rem;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .map-checkbox:checked + label .map-checkbox-label-check{
        border-color: var(--lightest-blue);
    }

.map-checkbox-label:hover {
    /* transition: filter 0.1s ease-in; */
}
.map-checkbox:checked + label .map-checkbox-label-bg,
.map-checkbox-label:hover .map-checkbox-label-bg{
    filter: grayscale(0);
}

.map-checkbox-label-name{
    color: inherit;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.25rem;
}
